.send_email_contact_button {
  font-weight: 700;
  fill: #000000;
  color: #000000;
  height: 40px;
  width: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: #ffffff;
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-color: #882cb0;
  border-radius: 30px 30px 30px 30px;
  transition: transform 0.5s;
  cursor: pointer;
}

.send_email_contact_button:hover {
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -ms-transform: scale(1.1);
  -o-transform: scale(1.1);
  transform: scale(1.1);
  color: #ffffff;
  background-color: transparent;
  background-image: radial-gradient(at bottom left, #f23891 0%, #5c2482 100%);
  border-color: #aa28f2;
}
