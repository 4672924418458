.Header_Container {
  width: 100%;
  height: 200px;
  top: 0;
  left: 0;
  position: Fixed;
}

.nav_container {
  display: flex;
  align-items: center;
  gap: 50px;
}

.hover-line {
  display: inline-block;
  position: relative;
  color: #1b1d2099;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  line-height: 24px;
}

/* .hover-line {
  color: #1b1d2099;
} */

.hover-line::after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 1px;
  bottom: 0;
  left: 0;
  background-color: #522582;
  transform-origin: bottom right;
  transition: transform 0.5s;
}

.hover-line:hover::after {
  transform: scaleX(1);
  color: #a0c539;
  transform-origin: bottom left;
}

.hover-line.active {
  color: #1b1d2099 !important;
}

@media (min-width: 1250px) {
  .header-container {
    max-width: 1200px;
  }
}

.header-container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (max-width: 720px) {
  .nav_container {
    display: none;
    flex-direction: column;
  }
}

@media (min-width: 721px) {
  .nav_container_mobile {
    display: none;
  }
}

.nav-burger {
  display: flex;
  justify-content: flex-end;
}

@media (min-width: 721px) {
  .nav-burger {
    display: none;
  }
}

.menu-options-text-active {
  color: #522582;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  line-height: 24px;
}
