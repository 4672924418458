.contact_container {
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 40px 100px 40px 100px;
  margin: auto;
  /* /* display: flex; */
  justify-content: center;
  align-items: center;
}

.contextdiv {
  display: flex;
  margin-top: 40px;
  gap: 10px;
}

.buttonDiv {
  display: flex;
  margin: 40px;
  justify-content: flex-end;
}
.mapDiv {
  width: 491px;
  height: 541px;
}

.textInputView {
  width: 278px;
  height: 55px;
  border-bottom: 1px solid #000000;
  margin-top: 12px;
  /* // display: "flex",
  // flexDirection: "column", // Ensure proper layout for <p> and <input> */
}

.anyQyation {
  font-family: "Poppins";
  font-size: 22px;
  line-height: 31px;
  font-weight: 300;
  color: #1d1b20cc;
}
.leftWrap {
  height: 500px;
  width: 550px;
  margin: auto;
}
.partoftext {
  font-family: "Poppins";
  font-size: 46px;
  font-weight: 600;
  line-height: 64.86px;
  text-align: center;
  /* display: flex; */
  align-items: center;
  justify-content: center;
  color: white;
}

.partoftextcontainer {
  background-color: #a1c43a;
  /* display: flex; */
  padding: 15px;
  align-items: center;
  justify-content: center;
  align-self: center;
  margin: 150px 134px 50px;
  border-radius: 15px;
  text-align: center;
}

.downloadtext {
  font-family: "Poppins";
  font-size: 26px;
  font-weight: 400;
  line-height: 36.66px;
  color: #ffffffe5;
  text-decoration-skip-ink: none;
}
.buttoncontainer {
  display: flex;
  max-width: 175px;
  width: "100%";
  max-height: 58px;
  height: "100%";
  gap: 0px;
  border-radius: 8px;
  opacity: 0px;
  background-color: #1c1b1e;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
  /* padding-right: 10px; */
}
.contecttext {
  font-family: "Poppins";
  font-size: 42px;
  line-height: 63px;
  font-weight: 600;
  color: #1b1d20e5;
}

@media (max-width: 998px) {
  .about-sub-container-main {
    flex-direction: column;
  }
}
@media (max-width: 998px) {
  .buttonDiv {
    display: flex;
    margin: 40px;
    justify-content: center;
  }
}

@media (max-width: 998px) {
  .anyQyation {
    font-family: "Poppins";
    font-size: 18px;
    line-height: 20px;
    font-weight: 300;
    color: #1d1b20cc;
  }
}

@media (max-width: 998px) {
  .contecttext {
    font-family: "Poppins";
    font-size: 22px;
    line-height: 30px;
    font-weight: 600;
    color: #1b1d20e5;
  }
}

@media (max-width: 998px) {
  .contact_container {
    margin-top: 0px;
    margin-bottom: 0px;
    padding: 50px 10px 20px 30px;
    margin: auto;

    justify-content: center;
    align-items: center;
  }
}

@media (max-width: 998px) {
  .imageWrap {
    display: column;
    width: 100%;
    align-items: center;
    justify-content: center;
  }
}

@media (max-width: 998px) {
  .textwrap {
    width: 100%;
  }
}

@media (max-width: 998px) {
  .downloadtext {
    font-family: "Poppins";
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    color: #ffffffe5;
    text-decoration-skip-ink: none;
  }
}

@media (max-width: 998px) {
  .partoftextcontainer {
    background-color: #a1c43a;
    /* display: flex; */
    padding: 15px;
    align-items: center;
    justify-content: center;
    align-self: center;
    margin: 0px 134px 100px;
    border-radius: 15px;
    text-align: center;
    margin: 0px 40px 10px;
  }
}

@media (max-width: 998px) {
  .contextdiv {
    display: flex;
    margin-top: 40px;
    flex-direction: column;
  }
}

@media (max-width: 998px) {
  .textInputView {
    width: 100%;
    height: 55px;
    border-bottom: 1px solid #000000;
    margin-top: 12px;
    /* // display: "flex",
    // flexDirection: "column", // Ensure proper layout for <p> and <input> */
  }
}

@media (max-width: 998px) {
  .leftWrap {
    margin: auto;
    height: 1000%;
    width: 100%;
  }
}

@media (max-width: 998px) {
  .mapDiv {
    width: 79%;
    height: 300px;
  }
}

@media (max-width: 998px) {
  .contact_img {
    width: 100%;
    height: 355px;
  }
}

@media screen and (max-width: 998px) {
  .partoftext {
    font-family: "Poppins";
    font-size: 24px;
    font-weight: 600;
    line-height: 33px;
    text-align: center;
    /* display: flex; */
    align-items: center;
    justify-content: center;
    color: white;
  }
}

.contact-text-header {
  color: #2c234d;
  margin: 0px 0px 15px 0px;
  font-family: "Poppins", Sans-serif;
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
}
.contact_trutab_add {
  padding: 0px 0px 30px 0px;
  font-family: "Poppins", Sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  color: #4c5267;
}
.contact_button {
  font-weight: 700;
  fill: #000000;
  color: #000000;
  height: 40px;
  width: 150px;
  display: flex;
  color: #ffffff;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: #a1c43a;
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  /* border-color: #882cb0; */
  border-radius: 30px 30px 30px 30px;
  transition: transform 0.5s;
  /* cursor: pointer; */
}
