.footer-container {
  background-color: white;
  padding: 10px;
}
.footer-sub-container {
  margin-right: auto;
  margin-left: auto;
  display: flex;
  align-items: center;
}

@media (max-width: 720px) {
  .footer-sub-container {
    flex-direction: column;
  }
}

.footer-text {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #5d6370;
}
