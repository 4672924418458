.error-toast {
  box-shadow: -5px 5px 0px #f24326;
}
.success-toast {
  box-shadow: -5px 5px 0px #149e52;
}
.warning-toast {
  box-shadow: -5px 5px 0px #fdce57;
}
.info-toast {
  box-shadow: -5px 5px 0px #9753fa;
}

.toast {
  padding: 1rem;
  max-width: 600px;
  /* max-height: 200px; */
  height: auto;
  width: auto;
  box-sizing: border-box;
  border-radius: 4px;
  border: 1px solid #020002;
  overflow: hidden;
  background: #ffffff;
  color: black;
}
.toast-title-container {
  display: flex;
  align-items: center;
}
.toast-icon {
  margin-right: 15px;
  width: 18px;
}
.toast-title {
  font-size: 14px;
  font-weight: 700;
}
.toast-message {
  font-size: 12px;
  font-weight: 400;
}
