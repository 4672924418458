.about-container {
  display: flex;
  flex-direction: column;
  position: relative;
}
.about-sub-container {
  display: flex;
  flex-direction: column;
  position: relative;
}

.about-header-text {
  /* display: flex; */
  font-size: 42px;
  font-weight: 500;
  line-height: 49px;
  padding-top: 40px;
  margin-bottom: 20px;
  color: #2c234d;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.aboutsub-header-text {
  /* display: flex; */
  font-size: 22px;
  font-weight: 300;
  line-height: 31px;
  /* padding-top: 40px; */
  margin-bottom: 2px;
  color: #2c234d;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.about-first-left-text-header {
  color: #4d446b;
  font-weight: 600;
  font-size: 26px;
  line-height: 36px;
  /* font-family: "Poppins", sans-serif; */
  margin-bottom: 22px;
}

.about-first-left-text-desc {
  color: #707b8e;
  font-size: 16px;
  line-height: 28px;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  margin-bottom: 22px;
}

.about-first-left-text-descwarp {
  /* color: #7bd051; */
  font-size: 18px;
  line-height: 30.6px;
  font-weight: 300;
  font-family: "Poppins", sans-serif;
  margin-bottom: 22px;
}
.about-our-vision-header {
  color: #4d446b;
  font-size: 50px;
  line-height: 64px;
}
.about-our-vision-header-desc {
  color: #4d446b;
  font-size: 20px;
  /* line-height: 64px; */
}
.hilideText {
  color: #a1c43a;
}

@media (max-width: 700px) {
  .about-header-text {
    /* display: flex; */
    font-size: 24px;
    font-weight: 500;
    line-height: 33px;
    padding: 30px;
    margin-bottom: 10px;
    color: #2c234d;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
}

@media (max-width: 700px) {
  .about-first-left-text-descwarp {
    /* color: #7bd051; */
    font-size: 12px;
    line-height: 24px;
    font-weight: 300;
    font-family: "Poppins", sans-serif;
    margin-bottom: 22px;
  }
}

@media (max-width: 700px) {
  .aboutsub-header-text {
    /* display: flex; */
    font-size: 18px;
    font-weight: 300;
    line-height: 20px;
    /* padding-top: 40px; */

    color: #2c234d;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
}

@media (max-width: 720px) {
  .about-sub-1st-container {
    flex-direction: column-reverse;
  }
}
@media (max-width: 720px) {
  .about-sub-2st-container {
    flex-direction: column;
  }
}
@media (max-width: 720px) {
  .about-sub-3st-container {
    flex-direction: column-reverse;
  }
}
@media (max-width: 720px) {
  .about-sub-4st-container {
    flex-direction: column;
  }
}

@media (max-width: 480px) {
  .dot {
    display: none;
  }
}

.dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #f23891;
  position: absolute;
}

.dot:before,
.dot:after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  border-radius: 50%;
  background: #f23891;
  top: 0;
  left: 0;
}

@-webkit-keyframes pulsate {
  0% {
    opacity: 1;
    -webkit-transform: translate(-50%, -50%) scale(0.5);
    transform: translate(-50%, -50%) scale(0.5);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate(-50%, -50%) scale(4);
    transform: translate(-50%, -50%) scale(4);
  }
}

@keyframes pulsate {
  0% {
    opacity: 1;
    -webkit-transform: translate(-50%, -50%) scale(0.5);
    transform: translate(-50%, -50%) scale(0.5);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate(-50%, -50%) scale(4);
    transform: translate(-50%, -50%) scale(4);
  }
}

@-webkit-keyframes pulsates {
  0% {
    opacity: 1;
    -webkit-transform: translate(-50%, -50%) scale(0.3);
    transform: translate(-50%, -50%) scale(0.3);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate(-50%, -50%) scale(2);
    transform: translate(-50%, -50%) scale(2);
  }
}

@keyframes pulsates {
  0% {
    opacity: 1;
    -webkit-transform: translate(-50%, -50%) scale(0.3);
    transform: translate(-50%, -50%) scale(0.3);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate(-50%, -50%) scale(2);
    transform: translate(-50%, -50%) scale(2);
  }
}

.dot:before {
  -webkit-transform: scale(2.5);
  -ms-transform: scale(2.5);
  transform: scale(2.5);
  opacity: 0.3;
}

.dot:after {
  -webkit-transform: scale(4);
  -ms-transform: scale(4);
  transform: scale(4);
  opacity: 0.1;
}

.dot .round {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: rgba(242, 56, 146, 0.2);
  -webkit-animation: pulsate 3s infinite;
  animation: pulsate 3s infinite;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.imageWrap {
  width: 60%;
  align-items: center;
  justify-content: center;
  display: flex !important;
}
.about-sub-container-main {
  display: flex !important;
  /* width: 100%; */
  align-items: center;
  justify-content: center;
  margin: 20px;
}

.textwrap {
  width: 40%;
}

.partoftextcontainer {
  background-color: #a1c43a;
  /* display: flex; */
  padding: 15px;
  align-items: center;
  justify-content: center;
  align-self: center;
  margin: 0px 134px 100px;
  border-radius: 15px;
  text-align: center;
}

.partoftext {
  font-family: "Poppins";
  font-size: 46px;
  font-weight: 600;
  line-height: 64.86px;
  text-align: center;
  /* display: flex; */
  align-items: center;
  justify-content: center;
  color: white;
}

.downloadtext {
  font-family: "Poppins";
  font-size: 26px;
  font-weight: 400;
  line-height: 36.66px;
  color: #ffffffe5;
  text-decoration-skip-ink: none;
}
.buttoncontainer {
  display: flex;
  max-width: 175px;
  width: "100%";
  max-height: 58px;
  height: "100%";
  gap: 0px;
  border-radius: 8px;
  opacity: 0px;
  background-color: #1c1b1e;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
  /* padding-right: 10px; */
}

@media (max-width: 998px) {
  .about-sub-container-main {
    flex-direction: column;
  }
}

@media (max-width: 998px) {
  .imageWrap {
    display: column;
    width: 100%;
    align-items: center;
    justify-content: center;
  }
}

@media (max-width: 998px) {
  .textwrap {
    width: 95%;
  }
}

@media (max-width: 998px) {
  .partoftext {
    font-family: "Poppins";
    font-size: 24px;
    font-weight: 600;
    line-height: 33px;
    text-align: center;
    /* display: flex; */
    align-items: center;
    justify-content: center;
    color: white;
  }
}
@media (max-width: 998px) {
  .downloadtext {
    font-family: "Poppins";
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    color: #ffffffe5;
    text-decoration-skip-ink: none;
  }
}

@media (max-width: 998px) {
  .partoftextcontainer {
    background-color: #a1c43a;
    /* display: flex; */
    padding: 15px;
    align-items: center;
    justify-content: center;
    align-self: center;
    margin: 0px 134px 100px;
    border-radius: 15px;
    text-align: center;
    margin: 0px 40px 10px;
  }
}
