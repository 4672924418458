.news-hedader-text {
  color: #a1c43a;
  text-align: center;
  font-size: 48px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px 0 40px 0;
}

@media (max-width: 720px) {
  .news-hedader-text {
    font-size: 34px;
  }
}
