h2 {
  margin-bottom: 15px;
  font-size: 2rem;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
}

p {
  color: #4c5267;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  line-height: 30px;
  font-weight: 300;
}

.page_wrapper {
  font-weight: 400;
  font-size: 16px;
}

ol {
  padding-left: 50px;
  margin-bottom: 30px;
  color: #4c5267;
  font-family: "Poppins", sans-serif;
}

ul {
  list-style: disc;
  color: #4c5267;
  padding-left: 40px;
  line-height: 35px;
  font-family: "Poppins", sans-serif;
}
li {
  font-family: "Poppins", sans-serif;
}

h4 {
  margin-bottom: 15px;
  font-size: 1.5rem;
}

br {
  margin-bottom: 20px;
}

.line_margin {
  margin-bottom: 30px;
}

b {
  font-weight: 600;
}

h5 {
  font-size: 1.25rem;
}
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}
