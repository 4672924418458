.shipping_header {
  font-weight: 600;
  font-size: 1.75rem;
  color: #4c5267;
  font-family: "Poppins", sans-serif;
  margin-bottom: 15px;
  line-height: 1;
}

.shipping_Sub_header {
  font-weight: 500;
  font-size: 1.25rem;
  color: #4c5267;
  font-family: "Poppins", sans-serif;
  margin: 15px 0px;
  line-height: 1;
}
