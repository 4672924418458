.img_text_header {
  margin-bottom: 15px;
  font-size: 1.75rem;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  color: #4c5267;
  line-height: 2;
}

.privacy_text {
  margin-bottom: 50px;
  line-height: 2;
}

.privacy_text_para {
  color: #707b8e;
  line-height: 2;
}
