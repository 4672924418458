.buttoncontainer {
  display: flex;
  max-width: 175px;
  width: "100%";
  max-height: 58px;
  height: "100%";
  gap: 0px;
  border-radius: 8px;
  opacity: 0px;
  background-color: #1c1b1e;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  /* padding-right: 10px; */
}
.bestdoctext {
  font-size: 48px;
  font-weight: 600;
  font-family: "Poppins";
  line-height: 68px;
  color: #1b1d20e5;
}

.maincontainer {
  display: flex;
  margin: 50px 147px;
  align-items: center;
  justify-content: center;
  align-items: center;
  justify-content: center;
  max-width: "100%";
}
.downloadtext {
  font-family: "Poppins";
  font-size: 26px;
  font-weight: 400;
  line-height: 36.66px;
  color: #ffffffe5;
  text-decoration-skip-ink: none;
}

.partoftext {
  font-family: "Poppins";
  font-size: 46px;
  font-weight: 600;
  line-height: 64.86px;
  text-align: center;
  /* display: flex; */
  align-items: center;
  justify-content: center;
  color: white;
}
.vedioContainer {
  display: flex;
  width: 45%;
  align-self: center;
  justify-self: center;
}

.vedio {
  border-radius: 60px;
  max-width: 681px;
  width: "100%";
  max-height: 600px;
  height: "100%";
  position: relative;
}
.lefttextwap {
  width: 55%;
}

.docandCall {
  display: flex;
  background-color: #522582;
  margin: 50px 160px;
  padding: 30px;
  border-radius: 20px;
}
.leftwarp247 {
  width: 55%;
  align-items: center;
  padding: 15px;
}
.rightWarp27 {
  width: 45%;
  align-items: center;
  justify-content: center;
}
.partoftextcontainer {
  background-color: #a1c43a;
  /* display: flex; */
  padding: 30px;
  align-items: center;
  justify-content: center;
  align-self: center;
  margin: 20px 140px 100px;
  border-radius: 15px;
  text-align: center;
}
.whychoosetext {
  text-align: center;

  margin: 0px 250px;

  /* justify-content: center; */
}
.commonmobile {
  max-height: 400px;
  height: "100%";
  width: "100%";
  max-width: 400px;
}

.whychoosecontainer {
  display: flex;
  justify-content: center;
  margin: 50px 147px;
  gap: 50px;
  margin-bottom: 20px;
  position: relative;
}

.whychooseleftwap {
  width: 55%;
}
.whychooserightwap {
  width: 45%;
  position: relative;
}
.manimage {
  position: relative;
  max-width: 550px;
  width: "100%";
  max-height: 450px;
  height: "100%";
}

.indiantexttrusted {
  font-size: 26px;
  font-weight: 400;
  font-family: "Poppins";
  line-height: 36px;
  margin-top: 50px;
}

.contecttext {
  font-size: 18px;
  font-weight: "400";
  font-family: "Poppins";
  line-height: 30px;
}
.text247 {
  font-size: 42px;
  font-weight: "600";
  color: #a1c43a;
  line-height: 60px;
  font-family: "Poppins";
}
.colortext {
  color: white;
  font-weight: 500;
}
.truetabhealthtext {
  font-size: 18px;
  font-weight: "400";
  color: #ffffffcc;
  line-height: 25px;
  font-family: "Poppins";
}

.avlabletextrightwap {
  font-family: "Poppins";
  font-size: 18px;
  font-weight: "500";
  line-height: 27px;
  color: white;
}

.avlabletexTsubtrightwap {
  font-family: "Poppins";
  font-size: 16px;
  font-weight: 400;
  line-height: 23px;
  color: #ffffffcc;
  /* // textAlign: "right", */
  align-items: center;
  justify-content: center;
}

.moretext {
  font-size: 42px;
  font-weight: 500;
  line-height: 60px;
  color: #000000;
  font-family: "Poppins";
}
.meetevrytext {
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  color: #000000;
  font-family: "Poppins";
}

@media screen and (max-width: 999px) {
  .whychoosetext {
    margin: 0px 0px;
  }
}

@media screen and (max-width: 999px) {
  .downloadtext {
    font-family: "Poppins";
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    color: #ffffffe5;
    text-decoration-skip-ink: none;
  }
}

@media screen and (max-width: 999px) {
  .partoftext {
    font-family: "Poppins";
    font-size: 24px;
    font-weight: 600;
    line-height: 33px;
    text-align: center;
    /* display: flex; */
    align-items: center;
    justify-content: center;
    color: white;
  }
}

@media screen and (max-width: 999px) {
  .moretext {
    font-size: 22px;
    font-weight: 500;
    line-height: 31px;
    color: #000000;
    font-family: "Poppins";
  }
}
.healthtext {
  font-family: "Poppins";
  font-size: 24px;
  font-weight: 500;
  line-height: 33px;
  color: #000000;
}

.subtitel {
  font-family: "Poppins";
  font-size: 16px;
  font-weight: 300;
  line-height: 22px;
  color: #1d1b2099;
}

.chhoseIconediv {
  width: 50px;
  height: 40px;
  border-radius: 25px;
  align-content: center;
  justify-items: center;
  background-color: white;
  text-align: center;
}

.effortlestext {
  font-family: "Poppins";
  font-size: 18px;
  font-weight: 500;
  line-height: 26px;
}

.MoreDivData {
  width: 350px;
  padding: 50px;
  background-color: white;
  box-shadow: 15px 15px 15px 15px #fbffee66;
  border-radius: 15px;
  cursor: pointer;
}

.hoverMoreDivData {
  width: 350px;
  padding: 50px;
  background-color: #a1c43a;
  box-shadow: 15px 15px 15px 15px #fbffee66;
  border-radius: 15px;
  transition: color 0.3s ease;
}

@media (max-width: 998px) {
  .effortlestext {
    font-family: "Poppins";
    font-size: 12px;
    font-weight: 500;
    line-height: 17px;
  }
}

@media (max-width: 998px) {
  .MoreDivData {
    align-items: center;
    justify-items: center;
    width: 350px;
    padding: 50px;
    background-color: white;
    box-shadow: 15px 15px 15px 15px #fbffee66;
    border-radius: 15px;
    text-align: center;
  }
}

@media (max-width: 998px) {
  .meetevrytext {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #000000;
    font-family: "Poppins";
  }
}

@media (max-width: 998px) {
  .chhoseIconediv {
    width: 60px;
    height: 30px;
    border-radius: 30px;
    align-content: center;
    justify-items: center;
    background-color: white;
    text-align: center;
  }
}

@media (max-width: 998px) {
  .subtitel {
    font-family: "Poppins";
    font-size: 12px;
    font-weight: 300;
    line-height: 26px;
    color: #1d1b2099;
  }
}

@media (max-width: 998px) {
  .healthtext {
    font-family: "Poppins";
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    color: #000000;
  }
}

@media (max-width: 998px) {
  .avlabletexTsubtrightwap {
    font-family: "Poppins";
    font-size: 12px;
    font-weight: 400;
    line-height: 17px;
    color: #ffffffcc;
    /* // textAlign: "right", */
    align-items: center;
    justify-content: center;
  }
}

@media (max-width: 998px) {
  .avlabletextrightwap {
    font-family: "Poppins";
    font-size: 14px;
    font-weight: "500";
    line-height: 21px;
    color: white;
  }
}

@media (max-width: 998px) {
  .truetabhealthtext {
    font-size: 14px;
    font-weight: "400";
    color: #ffffffcc;
    line-height: 20px;
    font-family: "Poppins";
  }
}

@media (max-width: 998px) {
  .text247 {
    font-size: 22px;
    font-weight: "600";
    color: #a1c43a;
    line-height: 31px;
    font-family: "Poppins";
  }
}
@media (max-width: 998px) {
  .vediobg {
    position: absolute;
    bottom: -15px;
    left: 1px;
    max-height: 300px;
    height: "100%";
    max-width: 200px;
    width: "100%";
  }
}

@media (max-width: 998px) {
  .buttoncontainer {
    display: flex;
    /* max-width: 175px; */
    width: "100%";
    /* max-height: 30px; */
    height: "100%";
    gap: 0px;
    border-radius: 8px;
    opacity: 0px;
    background-color: #1c1b1e;
    align-items: center;
    justify-content: space-between;
    padding: 5px;
    /* padding-right: 10px; */
  }
}

@media (max-width: 998px) {
  .bestdoctext {
    font-size: 24px;
    font-weight: 600;
    font-family: "Poppins";
    line-height: 33px;
    color: #1b1d20e5;
  }
}
@media (max-width: 998px) {
  .contecttext {
    font-size: 12px;
    font-weight: 400;
    font-family: "Poppins";
    line-height: 20px;
  }
}

@media (max-width: 998px) {
  .manimage {
    position: relative;
    max-width: 300px;
    width: "100%" !important;
    max-height: 200px;
    height: "100%" !important;
  }
}
@media (max-width: 998px) {
  .manimage {
    position: relative;
    max-width: 300px;
    width: "100%" !important;
    max-height: 200px;
    height: "100%" !important;
  }
}
@media (max-width: 998px) {
  .whychooserightwap {
    width: 100%;
    align-items: center;
    justify-items: center;
    text-align: center;
  }
}

@media (max-width: 998px) {
  .whychooseleftwap {
    width: 100%;
  }
}
@media (max-width: 998px) {
  .indiantexttrusted {
    font-size: 16px;
    font-weight: 400;
    font-family: "Poppins";
    line-height: 22px;
    margin-top: 50px;
  }
}

@media (max-width: 998px) {
  .whychoosecontainer {
    display: column;
    justify-content: center;
    margin: 10px 10px;
    gap: 50px;
    margin-bottom: 20px;
    flex-direction: column;
  }
}
@media (max-width: 998px) {
  .vedio {
    border-radius: 60px;
    max-width: 400px;
    width: "100%";
    max-height: 350px;
    height: "100%";
    position: relative;
  }
}

.buttonDivWap {
  display: flex;
  align-items: center;

  gap: 30px;
  margin-top: 20px;
}

@media (max-width: 998px) {
  .partoftextcontainer {
    background-color: #a1c43a;
    /* display: flex; */
    padding: 30px;
    align-items: center;
    justify-content: center;
    align-self: center;
    margin: 15x 134px 100px;
    border-radius: 15px;
    text-align: center;
    margin: 0px 40px 10px;
  }
}

@media (max-width: 998px) {
  .buttonDivWap {
    display: flex;
    align-items: center;
    gap: 30px;
    margin-top: 20px;
    justify-content: center;
  }
}

@media (max-width: 998px) {
  .commonmobile {
    max-height: 200px;
    height: "100%";
    width: "100%";
    max-width: 200px;
  }
}

@media (max-width: 998px) {
  .maincontainer {
    flex-direction: column;
    margin: 10px 40px;
    text-align: center;
    justify-items: center;
  }
}
@media (max-width: 998px) {
  .vedioContainer {
    flex-direction: column;
    align-items: center;
    justify-self: center;
    text-align: center;
    width: 100%;
  }
}
@media (max-width: 998px) {
  .lefttextwap {
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
    margin-top: 15px;
    margin-bottom: 15px;
  }
}
@media (max-width: 998px) {
  .docandCall {
    flex-direction: column;
    margin: 30px 30px;
  }
}
@media (max-width: 998px) {
  .leftwarp247 {
    width: 100%;
  }
}
@media (max-width: 998px) {
  .rightWarp27 {
    width: 100%;
  }
}
