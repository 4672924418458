.img_text_header {
  margin-bottom: 15px;
  font-size: 2rem;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  color: #4c5267;
}

.img_text_sub_header {
  margin-bottom: 15px;
  font-size: 1.25rem;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  color: #4c5267;
}

.terms_header {
  width: 80%;
  display: flex;
}

.text_main_header {
  padding: 10px 0 10px 0;
  align-self: "center";
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@media (max-width: 770px) {
  .terms_header {
    flex-direction: column;
  }
}
